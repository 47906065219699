export default [
  {
    path: '/courseware/management',
    meta: {
      keepAlive: true
    },
    component: () => import('../views/courseware/official/List.vue')
  },
  {
    path: '/courseware/:id/version',
    component: () => import('../views/courseware/official/VersionList.vue')
  },
  {
    path: '/courseware/ppt/:action/:id',
    component: () => import('../views/courseware/official/PptEdit.vue')
  },
  {
    path: '/courseware/qgroup/:action/:id',
    component: () => import('../views/courseware/official/QGroupEdit.vue')
  },
  {
    path: '/courseware/file/:action/:id',
    component: () => import('../views/courseware/official/Edit.vue')
  },
  {
    path: '/courseware/lessonPlan/:action/:id/:templateId/:type',
    component: () => import('../views/courseware/official/LessonPlanEdit.vue')
  },
  {
    path: '/courseware/speak/:action/:id/:type',
    component: () => import('../views/courseware/official/SpeakEdit.vue')
  }
]
