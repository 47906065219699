export default [
  {
    path: '/appreciation',
    redirect: '/appreciation/list'
  },
  {
    path: '/appreciation/list',
    // meta: {
    //   keepAlive: true
    // },
    name: 'AppreciationList',
    component: () => import('../views/appreciation/list/index.vue')
  },
  {
    path: '/appreciation/config',
    name: 'AppreciationConfig',
    component: () => import('../views/appreciation/config/index.vue')
  },
  {
    path: '/appreciation/edit/:id',
    name: 'AppreciationEdit',
    component: () => import('../views/appreciation/edit/index.vue')
  },
  {
    path: '/appreciation/create',
    name: 'AppreciationCreate',
    component: () => import('../views/appreciation/edit/index.vue')
  },
  {
    path: '/appreciation/info/:id',
    name: 'AppreciationInfo',
    component: () => import('../views/appreciation/info/index.vue')
  },
  {
    path: '/appreciation/import',
    name: 'AppreciationImport',
    component: () => import('../views/appreciation/edit/import.vue')
  }
]
