export default [
  {
    path: '/courseware-square',
    name: 'coursewareSquareList',
    component: () => import('../views/courseware-square/List.vue')
  },
  {
    path: '/courseware-square/official/:id/preview',
    name: 'coursewareSquarePreview',
    component: () => import('../views/courseware-square/preview/OfficialPreview.vue')
  },
  {
    path: '/courseware-square/school/:id/preview',
    name: 'schoolCoursewareSquarePreview',
    component: () => import('../views/courseware-square/preview/SchoolPreview.vue')
  },
  {
    path: '/courseware-square/institution/:id/preview',
    name: 'institutionCoursewareSquarePreview',
    component: () => import('../views/courseware-square/preview/InstitutionPreview.vue')
  }
]
