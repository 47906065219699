<template>
  <div class="app" ref="resourceApp">
    <a-config-provider :getPopupContainer="getPopupContainer" :locale="zhCN"
      :transformCellText="handleTransformCellText">
      <template #renderEmpty>
        <a-empty>
          <template #description>
            暂无数据
          </template>
        </a-empty>
      </template>
      <router-view v-slot="{ Component }">
        <keep-alive>
          <component :key="$route.path" :is="Component" v-if="$route.meta.keepAlive" />
        </keep-alive>
        <component :is="Component" v-if="!$route.meta.keepAlive" />
      </router-view>
    </a-config-provider>
  </div>
</template>
<script>
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN'

export default {
  name: 'app',
  methods: {
    getPopupContainer (node) {
      // 过滤掉下拉菜单的情况 这两种情况挂载于全局
      const reg = /(ant-dropdown-trigger|ant-btn|ant-popover-open|ant-tooltip-open)/g
      if (node && !reg.test(node.className)) {
        return node.parentNode
      }
      return this.$refs.resourceApp
    },
    // 对table 的空数据进行兼容
    handleTransformCellText ({ text }) {
      if (text === undefined || text === null || typeof text === 'string') {
        return text && text.length > 0 ? text : '-'
      }
      return text
    }
  },
  setup () {
    return {
      zhCN
    }
  }
}
</script>

<style lang="less">
#app {
  background-color: #f1f2f6;
  margin: -24px;
  h1 {
    display: block;
    font-size: 2em;
    margin-block-start: 0.67em;
    margin-block-end: 0.67em;
    margin-inline-start: 0;
    margin-inline-end: 0;
    font-weight: 500;
  }
  h2, h3, h4, h5 {
    font-weight: 500;
  }
}

.app {
  color: #3d4457;
  height: 100%;
  padding-bottom: 32px;
}

#nav {
  padding: 30px;
}
</style>
