export default {
  SHEET_SONGS_CREATE: 'resource-sheet-songs.create',
  SHEET_SONGS_DETAIL: 'resource-sheet-songs.detail',
  SHEET_SONGS_VERSION: 'resource-sheet-songs.version',
  SHEET_SONGS_EDIT: 'resource-sheet-songs.edit',
  SHEET_SONGS_LIST: 'resource-sheet-songs.list',
  SHEET_SONGS_DELETE: 'resource-sheet-songs.delete',
  ENJOY_SONGS_LIST: 'resource-enjoy-songs.list',
  ENJOY_SONGS_EDIT: 'resource-enjoy-songs.edit',
  ENJOY_SONGS_CREATE: 'resource-enjoy-songs.create',
  ENJOY_SONGS_DETAIL: 'resource-enjoy-songs.detail',
  ENJOY_SONGS_VERSION: 'resource-enjoy-songs.version',
  ENJOY_SONGS_DELETE: 'resource-enjoy-songs.delete',
  SHEET_SONGS_COPY: 'resource-sheet-songs.copy',
  ENJOY_SONGS_COPY: 'resource-enjoy-songs.copy',
  ENJOY_SONGS_PUBLISH: 'resource-enjoy-songs.publish',
  SHEET_SONGS_PUBLISH: 'resource-sheet-songs.publish',

  SHEET_SONGS_PERSONAL_CREATE: 'resource-sheet-songs-personal.create',
  SHEET_SONGS_PERSONAL_DETAIL: 'resource-sheet-songs-personal.detail',
  SHEET_SONGS_PERSONAL_EDIT: 'resource-sheet-songs-personal.edit',
  SHEET_SONGS_PERSONAL_LIST: 'resource-sheet-songs-personal.list',
  SHEET_SONGS_PERSONAL_DELETE: 'resource-sheet-songs-personal.delete',
  ENJOY_SONGS_PERSONAL_LIST: 'resource-enjoy-songs-personal.list',
  ENJOY_SONGS_PERSONAL_EDIT: 'resource-enjoy-songs-personal.edit',
  ENJOY_SONGS_PERSONAL_CREATE: 'resource-enjoy-songs-personal.create',
  ENJOY_SONGS_PERSONAL_DETAIL: 'resource-enjoy-songs-personal.detail',
  ENJOY_SONGS_PERSONAL_DELETE: 'resource-enjoy-songs-personal.delete'
}
