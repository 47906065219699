export default [
  {
    path: '/knowledge-points',
    name: 'KnowledgePoints',
    component: () => import('../views/knowledge-points/List.vue')
  },
  {
    path: '/knowledge-points/:id/content/:type',
    name: 'KnowledgePointsEdit',
    component: () => import('../views/knowledge-points/Content.vue')
  }
]
