/**
 * 注册v-noPreventRight方法，用于禁用右键菜单模式,
 * @param app vue实例
 */
export const registerVDirective = (app: any) => {
  app.directive('nopreventright', {
    mounted (el: any) {
      el.oncontextmenu = () => {
        console.log('click right menu')
        return false
      }
    }
  })
}
