export default [
  {
    path: '/school-coursewares/',
    name: 'SchoolCoursewares',
    component: () => import('../views/courseware-database/school/List.vue')
  },
  {
    path: '/school-coursewares/:id/preview',
    name: 'SchoolCoursewaresPreview',
    component: () => import('../views/courseware-database/school/Preview.vue')
  },
  {
    path: '/institution-coursewares/',
    name: 'InstitutionCoursewares',
    component: () => import('../views/courseware-database/institution/List.vue')
  },
  {
    path: '/institution-coursewares/:id/preview',
    name: 'InstitutionCoursewaresPreview',
    component: () => import('../views/courseware-database/institution/Preview.vue')
  }

]
