export default [
  {
    path: '/lesson-plan-template',
    name: 'LessonPlanTemplate',
    component: () => import('../views/lesson-plan-template/List.vue')
  },
  {
    path: '/lesson-plan-template/edit/:templateId',
    name: 'LessonPlanTemplateEdit',
    component: () => import('../views/lesson-plan-template/Edit.vue')
  }
]
