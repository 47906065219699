export default [
  {
    path: '/audit-management/official',
    name: 'PlatformAuditManagement',
    component: () => import('../views/audit-management/platform/List.vue')
  },
  {
    path: '/audit-management/official/:recordId/preview',
    name: 'PlatformAuditManagementPreview',
    component: () => import('../views/audit-management/platform/Preview.vue')
  },
  {
    path: '/audit-management/school',
    name: 'SchoolAuditManagement',
    component: () => import('../views/audit-management/school/List.vue')
  },
  {
    path: '/audit-management/school/:recordId/preview',
    name: 'SchoolAuditManagementPreview',
    component: () => import('../views/audit-management/school/Preview.vue')
  },
  {
    path: '/audit-management/institution',
    name: 'InstitutionAuditManagement',
    component: () => import('../views/audit-management/institution/List.vue')
  },
  {
    path: '/audit-management/institution/:recordId/preview',
    name: 'InstitutionAuditManagementPreview',
    component: () => import('../views/audit-management/institution/Preview.vue')
  }

]
