export default {
  token: localStorage.getItem('resource_token'),
  permissions: [],
  mainAppProps: {},
  ossConfig: null,
  baseURL: '',
  currentTeachingMaterial: JSON.parse(localStorage.getItem('current_teaching_material') || '{}'),
  mainAppName: '',
  themeColor: '',
  config: {
    apiPrefix: ''
  },
  currentPeriods: {},
  rootEl: null,
  hasPreloadPPT: false,
  checkShouldRefresh: undefined,
  redirectLogin: undefined,
  currentUser: undefined,
  // 客服微信二维码url
  keFuWechatQrCodeUrl: '',
  customConfig: undefined
}
