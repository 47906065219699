export default {
  TEACHING_MATERIAL_LIST: 'resource-teaching-material.list',
  TEACHING_MATERIAL_CREATE: 'resource-teaching-material.create',
  TEACHING_MATERIAL_EDIT: 'resource-teaching-material.edit',
  TEACHING_MATERIAL_DELETE: 'resource-teaching-material.delete',
  TEACHING_MATERIAL_PPT: 'resource-teaching-material.ppt',
  TEACHING_MATERIAL_COPY: 'resource-teaching-material.copy',

  TEACHING_MATERIAL_PERSONAL_LIST: 'resource-teaching-material-personal.list',
  TEACHING_MATERIAL_PERSONAL_CREATE: 'resource-teaching-material-personal.create',
  TEACHING_MATERIAL_PERSONAL_EDIT: 'resource-teaching-material-personal.edit',
  TEACHING_MATERIAL_PERSONAL_DELETE: 'resource-teaching-material-personal.delete',
  TEACHING_MATERIAL_PERSONAL_PPT: 'resource-teaching-material-personal.ppt'
}
