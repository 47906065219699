import client from 'webpack-theme-color-replacer/client'
import { generate } from '@ant-design/colors/dist/index'
import config from '@/config'

function getAntdSerials (color) {
  // 淡化（即less的tint）
  const lightens = new Array(9).fill().map((t, i) => {
    return client.varyColor.lighten(color, i / 10)
  })
  // colorPalette变换得到颜色值
  const colorPalettes = generate(color)
  const rgb = client.varyColor.toNum3(color.replace('#', '')).join(',')
  return lightens.concat(colorPalettes).concat(rgb)
}

// 修改颜色的核心方法
function changeColor (newColor) {
  var options = {
    newColors: getAntdSerials(newColor), // new colors array, one-to-one corresponde with `matchColors`
    changeUrl (cssUrl) {
      return `${config.serverURL}/${cssUrl}` // while router is not `hash` mode, it needs absolute path
    }
  }
  return client.changer.changeColor(options, Promise)
}

export default { changeColor }
