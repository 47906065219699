export default [
  {
    path: '/sheet-songs',
    component: () => import('../views/songs/sheet-songs/List.vue')
  },
  {
    path: '/sheet-songs/:id/detail/:type',
    component: () => import('../views/songs/sheet-songs/Detail.vue')
  },
  {
    path: '/sheet-songs/:id/version',
    component: () => import('../views/songs/sheet-songs/official/VersionList.vue')
  },
  {
    path: '/sheet-songs/create/:type',
    component: () => import('../views/songs/sheet-songs/sheet-song-form/SheetSongCreate.vue')
  },
  {
    path: '/sheet-songs/:id/edit/:type',
    component: () => import('../views/songs/sheet-songs/sheet-song-form/SheetSongEdit.vue')
  },
  {
    path: '/sheet-songs/management',
    meta: {
      keepAlive: true
    },
    component: () => import('../views/songs/sheet-songs/official/OfficialList.vue')
  },
  {
    path: '/sheet-songs/:id/version-detail/:versionId',
    component: () => import('../views/songs/sheet-songs/official/VersionDetail.vue')
  },
  {
    path: '/sheet-songs/import',
    component: () => import('../views/songs/sheet-songs/official/Import.vue')
  },
  {
    path: '/enjoy-songs',
    component: () => import('../views/songs/enjoy-songs/List.vue')
  },
  {
    path: '/enjoy-songs/management',
    meta: {
      keepAlive: true
    },
    component: () => import('../views/songs/enjoy-songs/official/OfficialList.vue')
  },
  {
    path: '/enjoy-songs/:id/detail/:type',
    component: () => import('../views/songs/enjoy-songs/Detail.vue')
  },
  {
    path: '/enjoy-songs/:id/version',
    component: () => import('../views/songs/enjoy-songs/official/VersionList.vue')
  },
  {
    path: '/enjoy-songs/create/:type',
    component: () => import('../views/songs/enjoy-songs/enjoy-song-form/EnjoySongCreate.vue')
  },
  {
    path: '/enjoy-songs/:id/edit/:type',
    component: () => import('../views/songs/enjoy-songs/enjoy-song-form/EnjoySongEdit.vue')
  },
  {
    path: '/enjoy-songs/:id/version-detail/:versionId',
    component: () => import('../views/songs/enjoy-songs/official/VersionDetail.vue')
  },
  {
    path: '/enjoy-songs/import',
    component: () => import('../views/songs/enjoy-songs/official/Import.vue')
  }
]
