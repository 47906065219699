import message from 'ant-design-vue/es/message'
// import defaultSettings from '../defaultSettings';
import themeColor from './themeColor.js'

// let lessNodesAppended
const colorList = [
  {
    key: 'shengyue', color: '#1890FF'
  },
  {
    key: '火山', color: '#FA541C'
  },
  {
    key: '日暮', color: '#FAAD14'
  },
  {
    key: '明青', color: '#13C2C2'
  },
  {
    key: '极光绿', color: '#52C41A'
  },
  {
    key: '极客蓝', color: '#2F54EB'
  },
  {
    key: '酱紫', color: '#722ED1'
  }
]

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const updateTheme = newPrimaryColor => {
  themeColor.changeColor(newPrimaryColor)
}

export { updateTheme, colorList }
