import store from '@/store'
import { PermissionMap } from './config'

// 全局管理员的判断
export const isSystemAdmin = () => {
  const list: any[] = store.state.permissions
  if (list.indexOf(PermissionMap.SYSTEMADMIN) > -1) {
    return true
  }
  return false
}

export const checkPermission = (permissionName: any) => {
  if (isSystemAdmin()) {
    return true
  }
  if (permissionName === undefined || permissionName === null) {
    return false
  }

  if (permissionName instanceof Array) {
    let hasPermission = false
    permissionName.forEach(item => {
      if (findInPermissionList(item)) {
        hasPermission = true
      }
    })
    return hasPermission
  }

  const type = typeof permissionName
  if (type === 'string') {
    return findInPermissionList(permissionName)
  }

  return false
}

export const findInPermissionList = (name: any) => {
  const permissionList = store.state.permissions
  return permissionList.filter(item => item === name).length > 0
}

export const filterPermiison = (list: any[]) => {
  return list.filter(item => item.permission === undefined || findInPermissionList(item.permission))
}

export const initPermissionScript = (vueApp: any) => {
  // 普通的组件使用的权限指令
  vueApp.directive('permission', {
    mounted (el:any, binding: any) {
      if (!isSystemAdmin()) {
        const permission = binding.value
        const flag = checkPermission(permission)
        if (!flag) {
          if (el.parentNode) {
            el.remove()
          } else {
            el.remove()
          }
        }
      }
    }

  })
  // 插槽里涉及到动态重新渲染的专用的permission权限控制，注意，使用该命令后不可以使用v-show，否则会产生冲突
  vueApp.directive('permission-in-slot', {
    mounted (el:any, binding: any) {
      if (!isSystemAdmin()) {
        const permission = binding.value
        const flag = checkPermission(permission)
        if (!flag) {
          el.style.display = 'none'
        }
      }
    }
  })
}

export { PermissionMap }
