export default [
  {
    path: '/mine-coursewares',
    name: 'mineCoursewares',
    component: () => import('../views/mine-coursewares/List.vue')
  },
  {
    path: '/mine-coursewares/:id/preview',
    name: 'mineCoursewaresPreview',
    component: () => import('../views/mine-coursewares/Preview.vue')
  },
  {
    path: '/mine-coursewares/:id/:action',
    name: 'mineCoursewaresEdit',
    component: () => import('../views/mine-coursewares/EditPpt.vue')
  },
  {
    path: '/mine-coursewares/share-list',
    component: () => import('../views/mine-coursewares/share/List.vue')
  },
  {
    path: '/mine-coursewares/share-list/:recordId/:id/preview',
    component: () => import('../views/mine-coursewares/share/Preview.vue')
  },
  {
    path: '/mine-coursewares/:action/:id/:type/:template/:node',
    component: () => import('../views/mine-coursewares/components/LessonPlanEdit.vue')
  }
]
