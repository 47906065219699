export default {
  KNOWLEDGE_POINTS_LIST: 'resource-knowledge-points.list',
  KNOWLEDGE_POINTS_INFO: 'resource-knowledge-points.info',
  KNOWLEDGE_POINTS_CREATE: 'resource-knowledge-points.create',
  KNOWLEDGE_POINTS_EDIT: 'resource-knowledge-points.edit',
  KNOWLEDGE_POINTS_DELETE: 'resource-knowledge-points.delete',
  KNOWLEDGE_POINTS_COPY: 'resource-knowledge-points.copy',

  KNOWLEDGE_POINTS_PERSONAL_LIST: 'resource-knowledge-points-personal.list',
  KNOWLEDGE_POINTS_PERSONAL_INFO: 'resource-knowledge-points-personal.info',
  KNOWLEDGE_POINTS_PERSONAL_CREATE: 'resource-knowledge-points-personal.create',
  KNOWLEDGE_POINTS_PERSONAL_EDIT: 'resource-knowledge-points-personal.edit',
  KNOWLEDGE_POINTS_PERSONAL_DELETE: 'resource-knowledge-points-personal.delete'
}
