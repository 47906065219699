export default {
  mainApps: {
    shenyue: {
      themeColor: '#1890ff',
      baseURL: 'https://testplatform-api.ecoach.evideo.tech',
      apiPrefix: '/backend/resource'
    },
    teacherPlatform: {
      themeColor: '#1ec38f',
      // baseURL: 'http://testresource.ecoach.evideo.tech',
      baseURL: 'https://testuser-api.ecoach.evideo.tech/',
      // apiPrefix: ''
      apiPrefix: '/teacher/resource'
    },
    schoolPlatform: {
      themeColor: '#1ec38f',
      baseURL: 'https://testuser-api.ecoach.evideo.tech/',
      apiPrefix: '/teacher/resource'
    },
    institutionPlatform: {
      themeColor: '#1890ff',
      baseURL: 'https://testresource-center.ecoach.evideo.tech',
      apiPrefix: ''
    },
    // 通用服务接口调用相关配置
    commonService: {
      baseURL: 'https://testgateway.ecoach.evideo.tech',
      apiPrefix: '/api/common-service'
    },
    questionsBank: {
      baseURL: 'https://testexam-center-service.ecoach.evideo.tech',
      apiPrefix: ''
    }
  },
  serverURL: 'https://testresource.ecoach.evideo.tech',
  pptist: {
    name: 'pptist',
    entry: 'https://testppt.ecoach.evideo.tech'
    // entry: 'http://192.168.74.175:8090/'
    // entry: 'http://localhost:8090'
  },
  knowledgePoint: {
    name: 'knowledgePoint',
    entry: 'https://testppt.ecoach.evideo.tech'
    // entry: 'http://192.168.74.175:8090/'
  },
  musicScore: 'https://testmusic-score.ecoach.evideo.tech'
}
