export default {
  mainApps: {
    shenyue: {
      themeColor: '#1890ff',
      baseURL: 'https://devpplatform-api.ecoach.evideo.tech',
      apiPrefix: '/backend/resource'
    },
    teacherPlatform: {
      themeColor: '#1ec38f',
      baseURL: 'https://devpuser-api.ecoach.evideo.tech/',
      apiPrefix: '/teacher/resource'
    },
    schoolPlatform: {
      themeColor: '#1ec38f',
      baseURL: 'https://devpuser-api.ecoach.evideo.tech/',
      apiPrefix: '/teacher/resource'
    },
    institutionPlatform: {
      themeColor: '#1890ff',
      baseURL: 'https://devpresource-center.ecoach.evideo.tech',
      apiPrefix: ''
    },
    // 通用服务接口调用相关配置
    commonService: {
      baseURL: 'https://devpgateway.ecoach.evideo.tech',
      apiPrefix: '/api/common-service'
    },
    questionsBank: {
      baseURL: 'https://devpexam-center-service.ecoach.evideo.tech',
      apiPrefix: ''
    }
  },
  serverURL: 'http://localhost:8080',
  pptist: {
    name: 'pptist',
    entry: 'https://devpppt.ecoach.evideo.tech'
    // entry: 'http://localhost:8090'
  },
  knowledgePoint: {
    name: 'knowledgePoint',
    entry: 'https://devpppt.ecoach.evideo.tech'
  },
  musicScore: 'http://localhost:8081'
}
