import songs from './songs'
import teachingMaterial from './teachingMaterial'
import knowledgePoints from './knowledgePoints'
import courseware from './courseware'
import auditManagement from './audit-management'
import coursewareDatabase from './courseware-database'
import appreciation from './appreciation'

export const PermissionMap = {
  SYSTEMADMIN: 'SYSTEMADMIN',

  ...teachingMaterial,
  ...songs,
  ...knowledgePoints,
  ...courseware,
  ...auditManagement,
  ...coursewareDatabase,
  ...appreciation
}
