export default {
  // 校课件库相关
  COURSEWARE_DATABASE_SCHOOL: 'courseware-database-school',
  COURSEWARE_DATABASE_SCHOOL_LIST: 'courseware-database-school.list',
  COURSEWARE_DATABASE_SCHOOL_ENABLE: 'courseware-database-school.enable',
  COURSEWARE_DATABASE_SCHOOL_PREVIEW: 'courseware-database-school.preview',

  // 平台课件库相关
  COURSEWARE_DATABASE_INSTITUTION: 'institution-coursewares',
  COURSEWARE_DATABASE_INSTITUTION_LIST: 'institution-coursewares.list',
  COURSEWARE_DATABASE_INSTITUTION_ENABLE: 'institution-coursewares.enable',
  COURSEWARE_DATABASE_INSTITUTION_PREVIEW: 'institution-coursewares.preview'

}
