export default [
  {
    path: '/teaching-material',
    redirect: '/teaching-material/list'
  },
  {
    path: '/teaching-material/list',
    meta: {
      keepAlive: true
    },
    name: 'TeachingMaterialList',
    component: () => import('../views/teaching-material/list/TeachingMaterialList.vue')
  },
  {
    path: '/teaching-material/:id/preparation/:type',
    name: 'TeachingMaterialPreparation',
    component: () => import('../views/teaching-material/preparation/Preparation.vue')
  },
  {
    path: '/teaching-material/course-resource-management/list',
    meta: {
      keepAlive: true
    },
    name: 'CourseResourceManagement',
    component: () =>
      import(
        '../views/course-resource-management/list/CourseResourceManagementList.vue'
      )
  },
  {
    path: '/teaching-material/:id/preview',
    name: 'TeachingMaterialPreview',
    component: () => import('../views/teaching-material/preview/Preview.vue')
  }
]
