import './public-path.js'
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css'
import { eventCenter, event } from '@evideo/frontend-utils'
import { updateTheme } from '@/utils/theme'
import config from '@/config'
import { initPermissionScript } from '@/utils/auth/index'
import { registerVDirective } from './utils/preventRight/index'
let instance: any = null

if (!(window as any).__POWERED_BY_QIANKUN__) {
  const tmp = {
    mainAppProps: {
      name: 'shenyue',
      baseConfig: {
        getCurrentUser: () => undefined,
        getPermission: () => ['SYSTEMADMIN'],
        getToken: () => 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOi8vY29tbW9uLXNlcnZpY2UvYXV0aC9sb2dpbiIsImlhdCI6MTYzNzEzMjQzNSwiZXhwIjoxNjcyNjY0NDM1LCJuYmYiOjE2MzcxMzI0MzUsImp0aSI6InUzS29IQUZFbU00T2ltNXUiLCJzdWIiOjEsInBydiI6IjIzYmQ1Yzg5NDlmNjAwYWRiMzllNzAxYzQwMDg3MmRiN2E1OTc2ZjciLCJndWFyZCI6ImJhY2tlbmQifQ.YtzQgWXsPKxWBEQOaqomTphbNtfJE5ryL0Wu6Qj8V00',
        redirectLogin: () => undefined,
        checkShouldRefresh: () => undefined
      }
    }
  }
  render(tmp)
}

async function render (props : any = {}) {
  const { container, mainAppProps = {} } = props
  const { name, baseConfig } = mainAppProps
  store.state.mainAppProps = mainAppProps
  store.state.mainAppName = name ?? ''
  // 网址等配置信息
  store.state.config = config.mainApps[name] ?? {}
  const { getToken, getPermission, checkShouldRefresh, redirectLogin, getCurrentUser, getCustomConfig } = baseConfig
  let token = ''
  let permissions = []
  let currentUser:any
  let customConfig = {}
  if (getToken) {
    token = getToken()
  }
  if (getPermission) {
    permissions = await getPermission()
    console.log(permissions)
  }

  if (getCurrentUser) {
    currentUser = await getCurrentUser()
  }

  if (getCustomConfig) {
    customConfig = getCustomConfig()
  }

  store.commit('setToken', token)
  store.commit('setPermissions', [...permissions])
  store.commit('setCheckShouldRefresh', checkShouldRefresh)
  store.commit('setRedirectLogin', redirectLogin)
  store.commit('setCurrentUser', currentUser)
  store.commit('setCustomConfig', customConfig)

  const themeColor = config.mainApps[name]?.themeColor ?? config.defaultThemeColor
  store.state.themeColor = themeColor
  updateTheme(themeColor)
  store.state.baseURL = config.mainApps[name]?.baseURL ?? config.defaultBaseUrl
  const rootEl = container ? container.querySelector('#app') : document.querySelector('#app')
  store.state.rootEl = rootEl

  instance = createApp(App)
  instance.use(store).use(router).use(Antd)
  initPermissionScript(instance)
  registerVDirective(instance)
  instance.mount(rootEl)
}

export async function bootstrap () : Promise<void> {
  console.log('[vue] vue app bootstraped')
}

export async function mount (props: any) : Promise<void> {
  const { onGlobalStateChange, setGlobalState } = props
  eventCenter.registerSend(setGlobalState)
  onGlobalStateChange((state: any, prevState: any) => {
    console.log('[子应用]global', state, prevState)
    eventCenter.registerPubTrigger(state)
  }, true)
  // 订阅加载权限信息事件
  eventCenter.sub(event.RESOURCE_UPDATE_PERMISSION, res => {
    const { permissions, token } = res
    if (permissions) {
      store.commit('setPermissions', permissions)
    }
    if (token) {
      store.commit('setToken', token)
    }
  })
  render(props)
}
export async function unmount () : Promise<void> {
  console.log('[vue-resource] vue app unmount', instance)
  instance.unmount()
  instance._container.innerHTML = ''
  instance = null
}
