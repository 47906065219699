export default [
  {
    path: '/dev-page',
    redirect: '/dev-page/teaching-material/89/preparation/0'
  },
  {
    path: '/dev-page/teaching-material/:id/preparation/:type',
    component: () => import('../views/dev-page/DevPage.vue')
  },
  {
    path: '/dev-page/knowledge/:id/content/:type',
    component: () => import('../views/dev-page/DevPageKnowledge.vue')
  }
]
