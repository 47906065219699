import local from './config.local'
import development from './config.development'
import test from './config.test'
import pre from './config.pre'
import production from './config.production'
import commonConfig from './common.config'

const env = process.env.APP_ENV || 'development'

const envMap = { local, development, test, pre, production }

export default { ...envMap[env], ...commonConfig }
