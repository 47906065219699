import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Home from '../views/Home.vue'
import teachingMaterial from './teaching-material'
import songs from './songs'
import devPage from './dev-page'
import knowledgePoints from './knowledge-points'
import mineCoursewares from './mine-coursewares'
import courseware from './courseware'
import coursewareSquare from './courseware-square'
import auditManagement from './audit-management'
import coursewareDatabase from './courseware-database'
import dataOverview from './data-overview'
import lessonPlanTemplate from './lesson-plan-template'
import appreciation from './appreciation'

const packageName = require('../../package.json').appAlias

const prefixs = ['/resource', '/content-center', '/:insCode/resource']

const constructRouteRecordRaw = (item: any) => {
  if (item.redirect) {
    return {
      ...item,
      redirect: (<any>window).__POWERED_BY_QIANKUN__ ? `/resource${item.redirect}` : item.redirect,
      alias: prefixs.map(prefix => `${prefix}${item.path}`)
    }
  }
  return {
    ...item,
    alias: prefixs.map(prefix => `${prefix}${item.path}`)
  }
}

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: '资源中心'
    }
  },
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  ...teachingMaterial,
  ...songs,
  ...knowledgePoints,
  ...courseware,
  ...devPage,
  ...mineCoursewares,
  ...coursewareSquare,
  ...auditManagement,
  ...coursewareDatabase,
  ...dataOverview,
  ...lessonPlanTemplate,
  ...appreciation,
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: () => import('../views/exception/NotFound.vue')
  }
].map(item => constructRouteRecordRaw(item))

const router = createRouter({
  history: createWebHistory(),
  // history: createWebHistory((<any>window).__POWERED_BY_QIANKUN__ ? `${process.env.BASE_URL}${packageName}/` : process.env.BASE_URL),
  routes
})

export default router
