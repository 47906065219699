export default {
  // 平台审核相关
  AUDIT_MANAGEMENT_PLATFORM: 'audit-management-platform',
  AUDIT_MANAGEMENT_PLATFORM_LIST: 'audit-management-platform.list',
  AUDIT_MANAGEMENT_PLATFORM_AUDIT: 'audit-management-platform.audit',
  AUDIT_MANAGEMENT_PLATFORM_PREVIEW: 'audit-management-platform.preview',

  // 校审核相关
  AUDIT_MANAGEMENT_SCHOOL: 'audit-management-school',
  AUDIT_MANAGEMENT_SCHOOL_LIST: 'audit-management-school.list',
  AUDIT_MANAGEMENT_SCHOOL_AUDIT: 'audit-management-school.audit',
  AUDIT_MANAGEMENT_SCHOOL_PREVIEW: 'audit-management-school.preview',

  // 机构审核相关
  AUDIT_MANAGEMENT_INSTITUTION: 'audit-management-institution',
  AUDIT_MANAGEMENT_INSTITUTION_LIST: 'audit-management-institution.list',
  AUDIT_MANAGEMENT_INSTITUTION_AUDIT: 'audit-management-institution.audit',
  AUDIT_MANAGEMENT_INSTITUTION_PREVIEW: 'audit-management-institution.preview'
}
