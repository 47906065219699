export default {
  changeValue (state) {
    state.zz++
  },
  setToken (state, token) {
    state.token = token
    localStorage.setItem('resource_token', token)
  },
  setCurrentTeachingMaterial (state: any, currentTeachingMaterial: any) {
    state.currentTeachingMaterial = currentTeachingMaterial
    localStorage.setItem('current_teaching_material', JSON.stringify(currentTeachingMaterial))
  },
  setCurrentPeriods (state:any, periods: any) {
    state.currentPeriods = periods
  },
  setPermissions (state: any, permissions) {
    state.permissions = permissions
  },
  setOssConfig (state: any, config:any) {
    state.ossConfig = config
  },
  setHasPreloadPPT (state: any, preload: any) {
    state.hasPreloadPPT = preload
  },
  setCheckShouldRefresh (state: any, func: any) {
    state.checkShouldRefresh = func
  },
  setRedirectLogin (state: any, func: any) {
    state.redirectLogin = func
  },
  setCurrentUser (state: any, user:any) {
    state.currentUser = user
  },
  // 获取客服微信二维码url
  setKeFuQRCodeUrl (state: any, qrcode_url: any) {
    state.keFuWechatQrCodeUrl = qrcode_url
  },
  setCustomConfig (state:any, config: any) {
    state.customConfig = config
  }
}
